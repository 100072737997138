body{
  margin: 0;
  padding: 0;
}
.Carousel-img{

}
@media screen and (max-width: 600px) {
  .Carousel-img {
    /*height: auto !important;*/
    background-size: auto auto   !important;
    width: auto !important;
  }
}

@font-face {
  font-family:OpenSans;
  src:url(OpenSans.ttf);
}